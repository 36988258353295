import React from 'react'
import PropTypes from 'prop-types'
import HEBLogo from '../images/heb_logo.png'
import MTALogo from '../images/mi-tienda.svg'
import MaintenanceLogo from '../images/maintenance-logo.png'
import { areBothDatesToday, areDatesOnTheSameDay, formatDate } from '../helpers/dates'

MaintenanceMode.propTypes = {
  maintenanceMode: PropTypes.object.isRequired
}

function MaintenanceMode ({ maintenanceMode }) {
  const title = maintenanceMode.title || 'Estamos en mantenimiento'
  const mainImage = maintenanceMode.mainImage || MaintenanceLogo
  let message = ''

  const startDate = new Date(maintenanceMode.startDate)
  const endDate = new Date(maintenanceMode.endDate)

  if (areBothDatesToday(startDate, endDate)) {
    const formattedStartDate = `${formatDate(startDate, 'D [de] MMMM')}`

    const hoursRange = `<strong>${formatDate(
      startDate,
      'hh:mm A'
    ).toLowerCase()} a ${formatDate(endDate, 'hh:mm A').toLowerCase()}</strong>`

    message = `Hoy ${formattedStartDate}, de ${hoursRange}. </br>`
  } else if (areDatesOnTheSameDay(startDate, endDate)) {
    const formattedStartDate = `${formatDate(startDate, 'D [de] MMMM')}`

    const hoursRange = `<strong>${formatDate(
      startDate,
      'hh:mm A'
    ).toLowerCase()} a ${formatDate(endDate, 'hh:mm A').toLowerCase()}</strong>`

    message = `El día ${formattedStartDate}, de ${hoursRange}. </br>`
  } else {
    const formattedStartDate = `${formatDate(
      startDate,
      'D [de] MMMM'
    )} a las ${formatDate(startDate, 'hh:mm A').toLowerCase()}`

    const formattedEndDate = `${formatDate(
      endDate,
      'D [de] MMMM'
    )} a las ${formatDate(endDate, 'hh:mm A').toLowerCase()}`

    message = `Desde el día <strong>${formattedStartDate}</strong> hasta el día <strong>${formattedEndDate}</strong>. </br>`
  }

  message += maintenanceMode.message || 'Estaremos realizando mejoras en nuestro sistema. Durante este tiempo, no podrás iniciar sesión, te recomendamos realizar tus compras después de este horario.'

  return (
    <>
      <div className="App">
        <div className="align-items-center container-size">
          <div className="row row-login justify-content-center">
            <div className="col-12 login-header text-center">
              <div className="row">
                <div className="col-6 d-flex align-items-center justify-content-end">
                  <img alt="logo" src={HEBLogo} className="logo-header me-5" />
                </div>
                <div className="col-6 d-flex align-items-center justify-content-start border-start">
                  <img
                    alt="logo"
                    src={MTALogo}
                    className="logo-header-mi-tienda ms-5"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 col-sm-8 col-10">
              <div
                className="d-flex justify-content-center align-items-center flex-column"
                style={{ height: 'calc(100vh - 203px)' }}
              >
                <img src={mainImage} alt="Mantenimiento"></img>
                <h3 className="mt-4 text-center">{title}</h3>
                <p
                  className="mt-2 text-center"
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MaintenanceMode
