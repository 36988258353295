import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginService } from '../services/login-service'
import { PageTitle } from './common/page-title'
import { SsoCard } from './common/sso-card'
import { LoginProvidersButtons } from './common/login-providers-buttons'
import { LoginForm } from './common/login-form'
import { Divider } from './common/divider'
import { Disclaimer } from './common/disclaimer'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../helpers/card-body-column-description'
import { ClientContext } from '../context/client-context'
import { UserService } from '../services/user-service'
import { SignupService } from '../services/signup-service'
import { checkUserAgent } from '../helpers/check-user-agent'
import { useAccountOperationResultMessage } from '../hooks/useAccountOperationResultMessage'
import BackButton from './common/BackButton'
import { WhatsappHelpSuccessAlert } from './common/whatsapp/whatsapp-help-success-alert'

const queryParams = new URLSearchParams(window.location.search)

const clientId = queryParams.get('client_id')
const redirectUri = queryParams.get('redirect_uri')
const state = queryParams.get('state')

if (clientId) {
  sessionStorage.removeItem('client_id')
  sessionStorage.setItem('client_id', clientId)
}
if (redirectUri) {
  sessionStorage.removeItem('redirect_uri')
  sessionStorage.setItem('redirect_uri', redirectUri)
}
if (state) {
  sessionStorage.removeItem('state')
  sessionStorage.setItem('state', state)
}

sessionStorage.removeItem('authorizationCode')
sessionStorage.removeItem('socialNetwork')
function Login () {
  const { isMiTienda } = useContext(ClientContext)

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [verifiedEmail, setVerifiedEmail] = useState(false)

  const [password, setPassword] = useState('')
  const [google, setGoogle] = useState(false)
  const [facebook, setFacebook] = useState(false)
  const [apple, setApple] = useState(false)

  const [loading, setLoading] = useState(false)

  const initialError = queryParams.get('error')
  const [error, setError] = useState(initialError ? new Error(initialError) : null)
  const successMessage = queryParams.get('success')
  const [success, setSuccess] = useState(successMessage && successMessage)
  const [showHelp, setShowHelp] = useState(false)

  const navigate = useNavigate()
  const stateFromCookie = sessionStorage.getItem('state')
  sessionStorage.removeItem('code_sent')

  useAccountOperationResultMessage()

  useEffect(() => {
    const os = checkUserAgent()
    const canShowAppleID = os !== 'Android' && os !== 'Linux'

    const fetchData = async () => {
      const waysToLogin = await LoginService().getWaysToLogIn()
      if (waysToLogin) {
        setGoogle(waysToLogin.google)
        setFacebook(waysToLogin.facebook)
        if (canShowAppleID) setApple(waysToLogin.apple)
      }
    }
    fetchData().then()
  }, [])

  const submit = async ({ username, password }) => {
    try {
      if (loading) return
      if (!validEmail) return setError(new Error('Ingrese un Email válido'))
      setLoading(true)
      setError(null)
      const { data } = await LoginService().loginByEmail({ username, password })
      if (data.reset) return navigate('/reset-password')
      sessionStorage.setItem('email', username)
      navigate('/login-success', { replace: true })
    } catch (err) {
      setError(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
  const verifyEmail = async email => {
    try {
      if (loading) return
      setLoading(true)
      const user = await UserService().getUserInfoByEmail({ email })
      const migratedUser = (user.confirmed_email === 'true' && !user.password) || user.password_change_needed === 'true'
      sessionStorage.setItem('migratedUser', migratedUser)

      if (!user.status) return navigate(`/signup?email=${email}`)
      if (migratedUser) return navigate(`/reset-password?email=${email}&migratedUser=true`)
      if (user.to_do_revisar_otros && !user.password) return navigate(`/reset-password?email=${email}`)
      if (user.confirmed_email !== 'true') {
        await SignupService().resendEmailCode({ email })
        return navigate(`/confirm-email?email=${email}`)
      }
      setVerifiedEmail(true)
    } catch (e) {
      console.error(e)
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const clearError = () => setError(null)
  const title = verifiedEmail ? 'Inicia sesión' : 'Bienvenido a ' + (isMiTienda ? 'Mi Tienda del Ahorro' : 'HEB')

  return (
    <>
      {verifiedEmail && (
        <div className="col-12 d-flex ms-2">
          <BackButton callback={(() => setVerifiedEmail(false))} />
        </div>
      )}
      {showHelp &&
        <div className="card-body pb-3 d-flex justify-content-center">
          <WhatsappHelpSuccessAlert
            onCloseAlert={() => setShowHelp(null)} />
        </div>
      }
      <PageTitle showBackButton={false} title={title} subtitle="Iniciar sesión" />
      <SsoCard>
        <LoginForm
          email={email}
          clearError={clearError}
          submit={() => verifiedEmail ? submit({ username: email, password }) : verifyEmail(email)}
          error={error}
          success={success}
          setEmail={setEmail}
          verifiedEmail={verifiedEmail}
          verifyEmail={() => verifyEmail(email)}
          setSuccess={setSuccess}
          setPassword={setPassword}
          setValidEmail={setValidEmail} />
        <div className="row justify-content-center">
          <div className={CARD_BODY_COLUMN_DESCRIPTION}>
            <button
              className={'button fw-bolder btn w-100 btn-login' + (isMiTienda ? ' mi-tienda' : '')}
              disabled={!validEmail || loading}
              onClick={() =>
                verifiedEmail ? submit({ username: email, password }) : verifyEmail(email)
              } >
              {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: '6px' }} role="status" aria-hidden="true"></span>}
              {verifiedEmail ? 'Iniciar sesión' : 'Continuar'}
            </button>
          </div>
          {verifiedEmail
            ? <>
              <Divider description="O" />
              <div className="row justify-content-center">
                <div className={`${CARD_BODY_COLUMN_DESCRIPTION} mt-2`}>
                  <button
                    className="button btn-lg btn btn-social w-100"
                    onClick={() => navigate(`/passwordless?email=${email}`)}>
                    <strong className="btn-social-content">Inicia sesión con clave de acceso</strong>
                  </button>
                </div>
              </div>
            </>
            : null}
          {!verifiedEmail && <>
            <Divider description="O"/>
            <LoginProvidersButtons
              facebook={facebook}
              apple={apple}
              state={state}
              google={google}
              redirectUri={redirectUri}/>
          </>
          }
          {stateFromCookie && <Disclaimer isMiTienda={isMiTienda} />}
        </div>
      </SsoCard>
    </>
  )
}

export default Login
